import(/* webpackMode: "eager" */ "/opt/buildhome/repo/.yarn/__virtual__/next-virtual-faab4feb66/2/.yarn/berry/cache/next-npm-15.0.0-canary.47-98610f37d6-10c0.zip/node_modules/next/dist/client/components/app-router.js");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/.yarn/__virtual__/next-virtual-faab4feb66/2/.yarn/berry/cache/next-npm-15.0.0-canary.47-98610f37d6-10c0.zip/node_modules/next/dist/client/components/client-page.js");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/.yarn/__virtual__/next-virtual-faab4feb66/2/.yarn/berry/cache/next-npm-15.0.0-canary.47-98610f37d6-10c0.zip/node_modules/next/dist/client/components/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/.yarn/__virtual__/next-virtual-faab4feb66/2/.yarn/berry/cache/next-npm-15.0.0-canary.47-98610f37d6-10c0.zip/node_modules/next/dist/client/components/layout-router.js");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/.yarn/__virtual__/next-virtual-faab4feb66/2/.yarn/berry/cache/next-npm-15.0.0-canary.47-98610f37d6-10c0.zip/node_modules/next/dist/client/components/not-found-boundary.js");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/.yarn/__virtual__/next-virtual-faab4feb66/2/.yarn/berry/cache/next-npm-15.0.0-canary.47-98610f37d6-10c0.zip/node_modules/next/dist/client/components/render-from-template-context.js");
